import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import useSpreadsheet from "../hooks/useSpreadsheet";

const Other = () => {
    const { data, loading, fetchOthers } = useSpreadsheet();
    const [categories, setCategories] = useState([])

    useEffect(() => {
        fetchOthers()
    }, []);

    useEffect(() => {
        const categories = new Set(data.map(item => item.category))
        setCategories(categories)
    }, [data]);

    function pascalCase(string) {
        const words = string.split(" ");

        return words.map((word) => {
            return word[0].toUpperCase() + word.substring(1);
        }).join(" ");
    }

    return (
        <div className="main-container">
            {loading ? <p>Loading...</p> : data.size === 0 ? <p>No data found</p> : categories.size > 0 ? Array.from(categories).map(category => {
                const itemsForCategory = data.filter(d => d.category === category);

                return (
                    <Col style={{ paddingBottom: 15 }} key={category}>
                        <Card.Title style={{ marginBottom: 10 }}>{category}</Card.Title>
                        {itemsForCategory.map(item => {
                            return (
                                <Col key={item.name} style={{ marginBottom: 15 }}>
                                    <Row>
                                        <Col><Card.Body>{pascalCase(item.name.toLowerCase())}</Card.Body></Col>
                                        <Col xs={2} sm={2}><Card.Body style={{ textAlign: "right" }}>{item.price}</Card.Body></Col>
                                    </Row>
                                    <Col> <Card.Body style={{ color: "#573b1d", fontStyle: "italic" }}>{item.flavours.toLowerCase()}</Card.Body></Col>
                                </Col>
                            );
                        })}
                    </Col>
                )
            })
                : null}
        </div>
    )
}

export default Other;