import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router';

const ListItemCard = (props) => {
    let navigate = useNavigate(); 

    return (
        <Card onClick={() => navigate(props.link)} style={{ marginTop: "25px", marginBottom: "25px", paddingTop: "10px", paddingBottom: "10px" }}>
            <Container>
                <Row className="align-items-center">
                    <Col xs={2} sm={2} md={2}><img alt="" src={props.icon} width={50}/></Col>
                    <Col>
                    <Card.Title>{props.title}</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">{props.subtitle}</Card.Subtitle>
                    </Col>
                    <Col xs={2} sm={2} md={2}><FontAwesomeIcon size="lg" icon={faChevronRight} color={"#573b1d"} /></Col>
                </Row>
            </Container>
        </Card>
    )
};

export default ListItemCard;