import React from "react";
import logo from "../assets/giraf-logo.png"

const Header = (props) => {

    return (
        <div className="main-container header girafHeader">
            <img src={logo} width="60%" alt="Giraf logo" />
        </div>
    )
}

export default Header;