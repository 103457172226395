import React from 'react';
import { Card, Col, Row, Tab, Tabs } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router';
import Beers from '../components/Beers';
import Spirits from '../components/Spirits';
import Other from '../components/Other';

const OtherDrinks = () => {
    let navigate = useNavigate();

    return (
        <div className='main-container'>
            <Row className="align-items-center" style={{ paddingBottom: 25, paddingTop: 10 }}>
                <Col xs={1} sm={1} onClick={() => navigate(-1)}><FontAwesomeIcon size="lg" icon={faArrowLeft} /></Col>
                <Col><Card.Title style={{ fontSize: 24 }}>Other drinks</Card.Title></Col>
            </Row>
            <Tabs
                defaultActiveKey="beers"
                id="controlled-tab-example"
                className="mb-3"
                justify
            >
                <Tab eventKey="beers" title="Beers">
                    <Beers />
                </Tab>
                <Tab eventKey="spirits" title="Spirits">
                    <Spirits />
                </Tab>
                <Tab eventKey="other" title="Other">
                    <Other />
                </Tab>
            </Tabs>
        </div>
    )
};

export default OtherDrinks;