import React, { useEffect, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router';
import useSpreadsheet from '../hooks/useSpreadsheet';
import { format, isBefore, sub } from 'date-fns';

const DJProgram = (props) => {
    let navigate = useNavigate();
    const { data, loading, fetchProgram } = useSpreadsheet();
    const [program, setProgram] = useState([]);

    useEffect(() => {
        fetchProgram()
    }, [])

    useEffect(() => {
        let filteredData = data.filter((d) => {
            const dayBeforeYesterday = sub(new Date(), { days: 2 })

            // Will split a date 16/1/2023 in [16, 1, 2023] -> [day, month, year]
            const date = getDate(d.date)
            return !isBefore(date, dayBeforeYesterday)
        })

        setProgram(filteredData)
    }, [data])

    function getDate(dateString) {
        // Will split a date 16/1/2023 in [16, 1, 2023] -> [day, month, year]
        const dateValues = dateString.split('/')
        return new Date(dateValues[2], dateValues[1] - 1, dateValues[0])
    }

    return (
        <div className="main-container">
            <Row className="align-items-center" style={{ paddingBottom: 25, paddingTop: 10 }}>
                <Col xs={1} sm={1} onClick={() => navigate(-1)}><FontAwesomeIcon size="lg" icon={faArrowLeft} /></Col>
                <Col><Card.Title style={{ fontSize: 24 }}>DJ program</Card.Title></Col>
            </Row>
            {loading ? <p>Loading...</p> : program.size === 0 ? <p>No data found</p> : program.map((data) => {
                const date = format(getDate(data.date), "dd/MM")
                
                return (
                    <Card key={data.date} style={{ marginBottom: 10, border: 'none' }}>
                        <Card.Header style={{ backgroundColor: "#573b1d", color: "#FFC300", fontSize: 12, fontWeight: 'bold' }}>{data.day.toUpperCase()} {date}</Card.Header>
                        <Card.Body>
                            <Card.Title style={{ marginBottom: 0, fontSize: 16 }}>{data.name.toUpperCase()}</Card.Title>
                            <Card.Body style={{ color: "#573b1d", fontStyle: "italic", fontSize: 14, padding: 0 }}>{data.genre}</Card.Body>
                        </Card.Body>
                    </Card>
                );
            })}
        </div>
    )
};

export default DJProgram;