import React, { useEffect } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router';
import useSpreadsheet from '../hooks/useSpreadsheet';
import Promo from '../assets/promo.png';

const Promos = () => {
    let navigate = useNavigate();
    const { data, loading, fetchPromos } = useSpreadsheet();

    useEffect(() => {
        fetchPromos()
    }, [])

    return (
        <div className="main-container">
            <Row className="align-items-center" style={{ paddingBottom: 25, paddingTop: 10 }}>
                <Col xs={1} sm={1} onClick={() => navigate(-1)}><FontAwesomeIcon size="lg" icon={faArrowLeft} /></Col>
                <Col><Card.Title style={{ fontSize: 24 }}>Promos</Card.Title></Col>
            </Row>
            {loading
                ? <p>Loading...</p>
                : data.size === 0
                    ? <>
                        <p>There are no promos.</p>
                        <Container key={data.promo} style={{ marginBottom: 15, border: 'none', backgroundColor: "#573b1d", borderRadius: 15 }}>
                            <Row style={{ paddingTop: 10 }} className='align-items-center' xs="auto" md="auto">
                                <Col>
                                    <img src={Promo} width={35} height={35} />
                                </Col>
                                <Col style={{ padding: 0, margin: 0 }}>
                                    <Card.Body style={{ color: "#FFC300", fontWeight: "bold", fontSize: 14 }}>INBEV PROMO'S</Card.Body>
                                </Col>
                            </Row>
                            <Card.Title style={{ paddingBottom: 15, paddingTop: 15, marginBottom: 0, fontSize: 16, color: "white" }}>
                                <a style={{ color: "white" }} target="_blank" href="https://tadaexperiences.be/offers/poc/6308998d-7b40-4574-9eda-50ca8b4d02f7">Klik hier voor onze INBEV acties.</a>
                            </Card.Title>
                        </Container>
                    </>
                    : <>
                        <Container key={data.promo} style={{ marginBottom: 15, border: 'none', backgroundColor: "#573b1d", borderRadius: 15 }}>
                            <Row style={{ paddingTop: 10 }} className='align-items-center' xs="auto" md="auto">
                                <Col>
                                    <img src={Promo} width={35} height={35} />
                                </Col>
                                <Col style={{ padding: 0, margin: 0 }}>
                                    <Card.Body style={{ color: "#FFC300", fontWeight: "bold", fontSize: 14 }}>INBEV PROMO'S</Card.Body>
                                </Col>
                            </Row>
                            <Card.Title style={{ paddingBottom: 15, paddingTop: 15, marginBottom: 0, fontSize: 16, color: "white" }}>
                                <a style={{ color: "white" }} target="_blank" href="https://tadaexperiences.be/offers/poc/6308998d-7b40-4574-9eda-50ca8b4d02f7">Klik hier voor onze INBEV promo's</a>
                            </Card.Title>
                        </Container>
                        {data.map((data) => {
                            if (data.promo == null || data.promo.trim() == "") {
                                return null
                            }

                            return (
                                <Container key={data.promo} style={{ marginBottom: 15, border: 'none', backgroundColor: "#573b1d", borderRadius: 15 }}>
                                    <Row style={{ paddingTop: 10 }} className='align-items-center' xs="auto" md="auto">
                                        <Col>
                                            <img src={Promo} width={35} height={35} />
                                        </Col>
                                        <Col style={{ padding: 0, margin: 0 }}>
                                            <Card.Body style={{ color: "#FFC300", fontWeight: "bold", fontSize: 14 }}>STUDENTENACTIE</Card.Body>
                                        </Col>
                                    </Row>
                                    <Card.Title style={{ paddingTop: 10, paddingBottom: 5, marginBottom: 0, fontSize: 14, color: "#FFC300", }}>ELKE {data.day.toUpperCase()}</Card.Title>
                                    <Card.Title style={{ paddingBottom: 15, marginBottom: 0, fontSize: 16, color: "white" }}>{data.promo}</Card.Title>
                                </Container>
                            );
                        })}
                    </>
            }
        </div>
    )
};

export default Promos;