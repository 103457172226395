import React, { useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";
import useSpreadsheet from "../hooks/useSpreadsheet";

const Spirits = () => {
    const { data, loading, fetchSpirits } = useSpreadsheet();

    useEffect(() => {
        fetchSpirits()
    }, []);

    function pascalCase(string) {
        const words = string.split(" ");

        return words.map((word) => {
            return word[0].toUpperCase() + word.substring(1);
        }).join(" ");
    }

    return (
        <div className="main-container">
            {loading ? <p>Loading...</p> : data.size === 0 ? <p>No data found</p> : data.map(d => {
                return (
                    <Col key={d.name} style={{ marginBottom: 15 }}>
                        <Row>
                            <Col><Card.Body>{pascalCase(d.name.toLowerCase())}</Card.Body></Col>
                            <Col xs={2} sm={2}><Card.Body style={{ textAlign: "right" }}>{d.price}</Card.Body></Col>
                        </Row>
                        <Col> <Card.Body style={{ color: "#573b1d", fontStyle: "italic" }}>{d.flavours.toLowerCase()}</Card.Body></Col>
                    </Col>
                );
            })}
        </div>
    )
}

export default Spirits;