import React from 'react';
import ListItemCard from '../components/ListItemCard';
import Shooter from '../assets/shooter-icon.png';
import Cocktail from '../assets/cocktail-icon.png';
import OtherDrinks from '../assets/drinks-icon.png';
import Roulette from '../assets/roulette-icon.png';
import DJProgram from '../assets/dj-icon.png';
import Promo from '../assets/promo-icon.png';
import { Alert } from 'react-bootstrap';

const Home = (props) => {
return (
    <div className="main-container">
      <Alert style={{marginTop: 15}} variant={'warning'}>
        <Alert.Heading>INBEV PROMO'S</Alert.Heading>
        Klik <Alert.Link href="https://tadaexperiences.be/offers/poc/6308998d-7b40-4574-9eda-50ca8b4d02f7">hier</Alert.Link> om onze INBEV promo's te bekijken.
      </Alert>

      <ListItemCard title="Shots" subtitle="Choose a shot from our extensive choice of over 150 shots!" link="/shooters" icon={Shooter} />
      <ListItemCard title="Cocktails" subtitle="Taste our delicious cocktails. Make sure to try our home signature cocktail: Giraf!" link="/cocktails" icon={Cocktail} />
      <ListItemCard title="Other drinks" subtitle="See our card of beers, sodas and many more!" link="/other" icon={OtherDrinks} />
      <ListItemCard title="Shot Roulette" subtitle="Let the weel decide for you!" link="/roulette" icon={Roulette} />
      <ListItemCard title="DJ program" subtitle="Check out our DJ program!" link="/djprogram" icon={DJProgram} />
      <ListItemCard title="Promos" subtitle="Check out our deals!" link="/promos" icon={Promo} />
    </div>
  )
};

export default Home;