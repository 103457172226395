import React, { useEffect, useState } from 'react'
import { Col, Row, Card, Button, Alert } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router';
import { Wheel } from 'react-custom-roulette';
import useSpreadsheet from '../hooks/useSpreadsheet';

const ShotRoulette = () => {
    const navigate = useNavigate();
    const [startSpinning, setStartSpinning] = useState(false)
    const [finished, setFinished] = useState(false)
    const [shooter, setShooter] = useState(null)
    const [prizeNumber, setPrizeNumber] = useState(0);

    const { data, fetchShooters } = useSpreadsheet()

    const numbers = [32, 15, 19, 4, 21, 2, 25, 17, 34, 6, 27, 13, 36, 11, 30, 8, 23, 10, 5, 24, 16, 33, 1, 20, 14, 31, 9, 22, 18, 29, 7, 28, 12, 35, 3, 26]
    const numbersData = [{ option: 0, style: { backgroundColor: 'green' } }].concat(numbers.map((number, index) => {
        if (index === 0 || index % 2 === 0) {
            return { option: number, style: { textColor: '#000' } }
        }
        return { option: number }
    }))

    useEffect(() => {
        fetchShooters()
    }, [])

    function onSpin() {
        const newPrizeNumber = Math.floor(Math.random() * numbersData.length)
        setPrizeNumber(newPrizeNumber)

        setShooter(null)
        setFinished(false)
        setStartSpinning(true)
    }

    function onStopSpinning() {
        setFinished(true)
        setStartSpinning(false)

        const totalShots = data.length - 1
        const randomShotInt = Math.floor(Math.random() * totalShots);
        setShooter(data[randomShotInt])
    }

    return (
        <div className='main-container'>
            <Row className="align-items-center" style={{ paddingBottom: 25, paddingTop: 10 }}>
                <Col xs={1} sm={1} onClick={() => navigate(-1)}><FontAwesomeIcon size="lg" icon={faArrowLeft} /></Col>
                <Col><Card.Title style={{ fontSize: 24 }}>Shot Roulette</Card.Title></Col>
            </Row>

            <Button style={{ marginBottom: 25 }} variant="primary" onClick={() => onSpin()}>Spin</Button>

            <Wheel
                mustStartSpinning={startSpinning}
                prizeNumber={prizeNumber}
                data={numbersData}
                innerBorderColor={'#000'}
                outerBorderColor={'#000'}
                radiusLineColor={'#000'}
                backgroundColors={['#573b1d', '#FFC300']}
                textColors={['#fff']}
                onStopSpinning={() => onStopSpinning()}
                innerRadius={60}
                radiusLineWidth={3}
                innerBorderWidth={10}
                outerBorderWidth={3}
                perpendicularText
                textDistance={80}
                fontSize={16}
            />

            {finished && shooter !== null ?
                <Alert style={{backgroundColor: '#573b1d', borderColor: '#573b1d', color: '#ffffff', marginTop: 15, textAlign: 'center'}} variant='primary'>
                    <Card.Subtitle>{shooter.name}</Card.Subtitle>
                </Alert>
                : null}
        </div>
    )
}

export default ShotRoulette;