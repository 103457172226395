import React from 'react';
import './App.css';
import { HashRouter, Route, Routes } from "react-router-dom";
import Header from './components/Header';
import Home from './screens/Home';
import Shooters from './screens/Shooters';
import Cocktails from './screens/Cocktails';
import OtherDrinks from './screens/OtherDrinks';
import ShotRoulette from './screens/ShotRoulette';
import DJProgram from './screens/DJProgram';
import Promos from './screens/Promos';

function App() {
  return (
    <div>
      <Header />
      <HashRouter>
        <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/shooters" element={<Shooters/>} />
        <Route path="/cocktails" element={<Cocktails/>} />
        <Route path="/other" element={<OtherDrinks/>} />
        <Route path="/roulette" element={<ShotRoulette/>} />
        <Route path="/djprogram" element={<DJProgram/>} />
        <Route path="/promos" element={<Promos/>} />
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
