import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Dropdown, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router';
import _ from 'lodash';
import useSpreadsheet from '../hooks/useSpreadsheet';

const A_Z = "A-Z"
const Z_A = "Z-A"
const PRICE_LOW = "Price (low to high)"
const PRICE_HIGH = "Price (high to low)"

const ALL_SHOTS = "All"
const NO_CATEGORY = "No category"

const Shooters = () => {
  let navigate = useNavigate();

  const { data, loading, fetchShooters } = useSpreadsheet();
  const [filteredData, setFilteredData] = useState(data)

  const [categories, setCategories] = useState([]);
  const [sortMethod, setSortMethod] = useState(A_Z);
  const [selectedCategory, setSelectedCategory] = useState("All")
  const [searchQuery, setSearchQuery] = useState("")

  useEffect(() => {
    fetchShooters()
  }, []);

  useEffect(() => {
    const categories = new Set(data.map(d => d.category))
    setCategories(Array.from(categories))
    setFilteredData(data)
  }, [data]);

  useEffect(() => {
    sortData(sortMethod)
  }, [sortMethod]);

  useEffect(() => {
    if (data.size === 0) {
      return;
    }

    let sortedData = sortData(sortMethod)

    if (searchQuery !== "") {
      sortedData = sortedData.filter(d => {
        let name = d.name.toLowerCase()
        let ingredients = d.ingredients.toLowerCase()
        return _.includes(name, searchQuery.trim().toLowerCase()) || _.includes(ingredients, searchQuery.trim().toLowerCase())
      })
    }

    if (selectedCategory === ALL_SHOTS) {
      setFilteredData(sortedData);
    } else if (selectedCategory.trim() === NO_CATEGORY) {
      setFilteredData(sortedData.filter(d => d.category.trim() === ''))
    } else {
      setFilteredData(sortedData.filter(d => d.category.trim() === selectedCategory.trim()))
    }
  }, [selectedCategory, sortMethod, searchQuery])

  function sortData(sortMethod) {
    if (data.size === 0) {
      return [];
    }

    let orderedData = data

    switch (sortMethod) {
      case A_Z:
        orderedData = _.orderBy(data, ["name"], ['asc']);
        break;
      case Z_A:
        orderedData = _.orderBy(data, ["name"], ['desc']);
        break;
      case PRICE_LOW:
        orderedData = _.orderBy(data, ["price", "name"], ["asc", "asc"]);
        break;
      case PRICE_HIGH:
        orderedData = _.orderBy(data, ["price", "name"], ["desc", "asc"]);
        break;
      default:
        orderedData = _.orderBy(data, ["name"], ['asc']);
        break;
    }

    return orderedData
  }

  return (
    <div className="main-container">
      <Row className="align-items-center" style={{ paddingBottom: 15, paddingTop: 10 }}>
        <Col xs={1} sm={1} onClick={() => navigate(-1)}><FontAwesomeIcon size="lg" icon={faArrowLeft} /></Col>
        <Col><Card.Title style={{ fontSize: 24 }}>Shots</Card.Title></Col>
      </Row>

      {loading ? <p>Loading...</p> : data.length === 0 ? <p>No data found</p> : <>
        {/* FILTERS */}
        <Row className="align-items-center" xs="auto">
          <Col>
            {/* SORT METHOD */}
            <Dropdown style={{ marginBottom: 15 }} size="sm">
              <Dropdown.Toggle variant="success" id="dropdown-basic" size="sm">
                Sort
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => setSortMethod(A_Z)}>{A_Z}</Dropdown.Item>
                <Dropdown.Item onClick={() => setSortMethod(Z_A)}>{Z_A}</Dropdown.Item>
                <Dropdown.Item onClick={() => setSortMethod(PRICE_LOW)}>{PRICE_LOW}</Dropdown.Item>
                <Dropdown.Item onClick={() => setSortMethod(PRICE_HIGH)}>{PRICE_HIGH}</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>

          {/* CHOOSE CATEGORY */}
          <Col>
            <Dropdown style={{ marginBottom: 15 }} size="sm">
              <Dropdown.Toggle variant="success" id="dropdown-basic" size="sm"> {selectedCategory === "" ? NO_CATEGORY : selectedCategory} ({filteredData.length}) </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => setSelectedCategory(ALL_SHOTS)}>{ALL_SHOTS} ({data.filter(d => searchQuery === "" ? true : _.includes(d.name.toLowerCase(), searchQuery.toLowerCase()) || _.includes(d.ingredients.toLowerCase(), searchQuery.toLowerCase())).length})</Dropdown.Item>
                {categories.map((c) => {
                  let results = data.filter(d => {
                    let searchQueryMatched = searchQuery.trim() === "" ? true : _.includes(d.name.toLowerCase(), searchQuery.trim().toLowerCase()) || _.includes(d.ingredients.toLowerCase(), searchQuery.trim().toLowerCase())
                    return d.category.trim() === c.trim() && searchQueryMatched
                  })
                  return (
                    <Dropdown.Item onClick={() => setSelectedCategory(c)}>{c === '' ? NO_CATEGORY : c} ({results.length})</Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>

        {/* SEARCH FIELD */}
        <Row>
          <Col>
            <Form.Control style={{ marginTop: 0, marginBottom: 25 }} type="search" placeholder="Search..." value={searchQuery} onChange={e => setSearchQuery(e.target.value)} />
          </Col>
        </Row>

        {/* LIST DATA */}
        {filteredData.map((data) => {
          let ingredients = data.ingredients.replace(/-/g, "•")
          return (
            <Col key={data.name}>
              <Row style={{ justifyContent: 'space-between' }} xs={'auto'} sm={'auto'}>
                <Col><Card.Subtitle>{data.name}</Card.Subtitle></Col>
                <Col><Card.Subtitle style={{ textAlign: "right" }}>{data.price}</Card.Subtitle></Col>
              </Row>
              <Row>
                <Col>
                  <Card.Body style={{ color: "#573b1d" }}>{ingredients}</Card.Body>
                </Col>
                <Col xs={1}></Col>
              </Row>
              <div style={{ borderTop: "1px solid #573b1d ", marginBottom: 15, marginTop: 15 }}></div>
            </Col>
          );
        })}
      </>}
    </div>
  )
};

export default Shooters;