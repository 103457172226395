import { useState } from "react";
import Papa from "papaparse";

export default function useSpreadsheet() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    let apiKey = "AIzaSyARnBB_m6bkVXfExDzJfpOoJHzd3aFDiOI";
    let sheetId = "1qtPa4g2PZdJ2mUeL8wcGcYFh_v2IA81rG3cTlmnO3Vs";

    function fetchSheet({ spreadsheetId, sheetName, apiKey }) {
        setLoading(true)

        let url = `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}?key=${apiKey}`;
        return fetch(url).then(response =>
            response.json().then(result => {
                let parsedData = Papa.parse(Papa.unparse(result.values), { header: true });
                setData(parsedData.data)
                setLoading(false)
            }).catch(() => setLoading(false))
        ).catch(() => setLoading(false));
    }

    function fetchShooters() {
        fetchSheet({
            spreadsheetId: sheetId,
            sheetName: 'Shooters',
            apiKey: apiKey,
        });
    }

    function fetchCocktails() {
        fetchSheet({
            spreadsheetId: sheetId,
            sheetName: 'Cocktails',
            apiKey: apiKey,
        });
    }

    function fetchBeers() {
        fetchSheet({
            spreadsheetId: sheetId,
            sheetName: 'Beers',
            apiKey: apiKey,
        });
    }

    function fetchSpirits() {
        fetchSheet({
            spreadsheetId: sheetId,
            sheetName: 'Spirits',
            apiKey: apiKey,
        });
    }

    function fetchOthers() {
        fetchSheet({
            spreadsheetId: sheetId,
            sheetName: 'Other',
            apiKey: apiKey,
        });
    }

    function fetchProgram() {
        fetchSheet({
            spreadsheetId: sheetId,
            sheetName: 'DJ',
            apiKey: apiKey,
        });
    }

    function fetchPromos() {
        fetchSheet({
            spreadsheetId: sheetId,
            sheetName: 'Promos',
            apiKey: apiKey,
        });
    }

    return {data, loading, fetchShooters, fetchCocktails, fetchBeers, fetchSpirits, fetchOthers, fetchProgram, fetchPromos};
}
