import React, { useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router';
import useSpreadsheet from '../hooks/useSpreadsheet';

const Cocktails = (props) => {
    let navigate = useNavigate();
    const { data, loading, fetchCocktails } = useSpreadsheet();

    useEffect(() => {
        fetchCocktails()
    }, [])

    return (
        <div className="main-container">
            <Row className="align-items-center" style={{ paddingBottom: 25, paddingTop: 10 }}>
                <Col xs={1} sm={1} onClick={() => navigate(-1)}><FontAwesomeIcon size="lg" icon={faArrowLeft} /></Col>
                <Col><Card.Title style={{ fontSize: 24 }}>Cocktails</Card.Title></Col>
            </Row>
            {loading ? <p>Loading...</p> : data.size === 0 ? <p>No data found</p> : data.map((data) => {
                return (
                    <Col key={data.name}>
                        <Row style={{ justifyContent: 'space-between' }} xs={'auto'} sm={'auto'}>
                            <Col><Card.Subtitle>{data.name}</Card.Subtitle></Col>
                            <Col><Card.Subtitle style={{ textAlign: "right" }}>{data.price}</Card.Subtitle></Col>
                        </Row>
                        <Row>
                            <Col>
                                <Card.Body style={{ color: "#573b1d" }}>{data.ingredients}</Card.Body>
                            </Col>
                            <Col xs={1}></Col>
                        </Row>
                        <div style={{ borderTop: "1px solid #573b1d ", marginBottom: 15, marginTop: 15 }}></div>
                    </Col>
                );
            })}
        </div>
    )
};

export default Cocktails;